/**
 * WIDTHS: Adjust all budget tables columns to the same width.
 */
table.table-accounting thead tr th:nth-child(1),
table.table-accounting tbody tr th:nth-child(1),
table.table-accounting tbody tr td:nth-child(1) {
  width: 40%;
  min-width: 200px;
}

table.table-accounting thead tr th:nth-child(2),
table.table-accounting tbody tr th:nth-child(2),
table.table-accounting tbody tr td:nth-child(2) {
  width: 20%;
  min-width: 120px;
}

table.table-accounting thead tr th:nth-child(3),
table.table-accounting tbody tr th:nth-child(3),
table.table-accounting tbody tr td:nth-child(3) {
  width: 20%;
  min-width: 120px;
}

table.table-accounting thead tr th:nth-child(4),
table.table-accounting tbody tr th:nth-child(4),
table.table-accounting tbody tr td:nth-child(4) {
  width: 20%;
  min-width: 120px;
}

table.table-accounting-3 thead tr th:nth-child(1),
table.table-accounting-3 tbody tr th:nth-child(1),
table.table-accounting-3 tbody tr td:nth-child(1) {
  width: 60%;
  min-width: 200px;
}

table.table-accounting-3 thead tr th:nth-child(2),
table.table-accounting-3 tbody tr th:nth-child(2),
table.table-accounting-3 tbody tr td:nth-child(2) {
  width: 20%;
  min-width: 120px;
}

table.table-accounting-3 thead tr th:nth-child(3),
table.table-accounting-3 tbody tr th:nth-child(3),
table.table-accounting-3 tbody tr td:nth-child(3) {
  width: 20%;
  min-width: 120px;
}
