/**
 * WIDTHS: Adjust all budget tables columns to the same width.
 */
table.table-budget thead tr th:nth-child(1),
table.table-budget tbody tr th:nth-child(1),
table.table-budget tbody tr td:nth-child(1) {
  width: 60%;
  min-width: 200px;
}

table.table-budget thead tr th:nth-child(2),
table.table-budget tbody tr th:nth-child(2),
table.table-budget tbody tr td:nth-child(2) {
  width: 20%;
  min-width: 120px;
}

table.table-budget thead tr th:nth-child(3),
table.table-budget tbody tr th:nth-child(3),
table.table-budget tbody tr td:nth-child(3) {
  width: 20%;
  min-width: 120px;
}

table.table-budget.col-5 thead tr th:nth-child(1),
table.table-budget.col-5 tbody tr th:nth-child(1),
table.table-budget.col-5 tbody tr td:nth-child(1) {
  width: 40%;
  min-width: 200px;
}

table.table-budget.col-5 thead tr th:nth-child(2),
table.table-budget.col-5 tbody tr th:nth-child(2),
table.table-budget.col-5 tbody tr td:nth-child(2) {
  width: 15%;
  min-width: 120px;
}

table.table-budget.col-5 thead tr th:nth-child(3),
table.table-budget.col-5 tbody tr th:nth-child(3),
table.table-budget.col-5 tbody tr td:nth-child(3) {
  width: 15%;
  min-width: 120px;
}

table.table-budget.col-5 thead tr th:nth-child(4),
table.table-budget.col-5 tbody tr th:nth-child(4),
table.table-budget.col-5 tbody tr td:nth-child(4) {
  width: 15%;
  min-width: 120px;
}

table.table-budget.col-5 thead tr th:nth-child(5),
table.table-budget.col-5 tbody tr th:nth-child(5),
table.table-budget.col-5 tbody tr td:nth-child(5) {
  width: 15%;
  min-width: 120px;
}

table.table-budget.col-12 thead tr th,
table.table-budget.col-12 tbody tr th,
table.table-budget.col-12 tbody tr td {
  width: calc(100% / 12);
  min-width: 20px;
}

/**
 * DRAGGABLE: Color the draggable elements in transit.
 */
tr.list-selected td {
  background-color: aliceblue;
}

tr.list-placeholder td {
  background-color: beige;
}

/**
 * BORDERS: Adjust borders to align in all outer and inner budget tables
 */
.budget-wrapper {
  border-bottom: 1px solid var(--bs-border-color);
}

.table-budget,
.table-spend {
  margin: 0;
}

.table-budget th,
.table-budget td {
  border-bottom: 0;
}

.table-budget td.draggable-wrapper {
  padding: 0;
}

.table-budget th:not(.draggable-wrapper),
.table-budget td:not(.draggable-wrapper) {
  border-top: 1px solid var(--bs-border-color);
  border-left: 1px solid var(--bs-border-color);
  border-bottom: 0;
}

.table-budget th:last-child:not(.draggable-wrapper),
.table-budget td:last-child:not(.draggable-wrapper) {
  border-right: 1px solid var(--bs-border-color);
}

/** var(--bs-border-color);*/